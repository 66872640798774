import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

const Page = ({ data }) => {
  const { title, content, description } = data.page;
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={[
          `Casa Amiga`,
          `Madre de día`,
          `Blog Dulces Miradas`,
          `${title}`,
        ]}
      />
      <div className="site-container blog-post">
        <div className="container">
          <article className="post">
            <h1 className="title">{title}</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: content.childMarkdownRemark.html,
              }}
            />
          </article>
        </div>
      </div>
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query SinglePage($slug: String!) {
    page: contentfulPaginas(slug: { eq: $slug }) {
      title
      content {
        childMarkdownRemark {
          html
        }
      }
      description
    }
  }
`;
